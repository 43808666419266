<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<div class="salud-report">
    <div class="row header-title">
        <div class="col-md-8 col-lg-10 title-line s-w-70">
            <h5> Certificados de Formaciones (PRESENCIALES)</h5>
        </div>
        <div class="col-md-4 col-lg-2 s-w-30">
            <a [routerLink]="['../pageindex']">Volver</a>
        </div>
    </div>
    <form [formGroup]="filterForm" name="trainingCertificateFilterForm" #f="ngForm" novalidate (ngSubmit)="btnFilter()">

        <div *ngIf="this.selectedTab == 1" class="row mb-2">
            <div class="col-md-6 col-lg-3 d-flex s-m-t-10">
                <input type="text" class="form-control form-control-sm" placeholder="Trabajador" formControlName="trabajadores"
                    (keyup)="onKeyUpTrabajadores($event)">                
            </div>
        </div>

        <div *ngIf="this.selectedTab == 2" class="row mb-2">
            <div class="col-md-6 col-lg-3 d-flex s-m-t-10">
                <ng-select #select (close)="select.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
                    [items]="filterEmployees" id="filter-employee" name="filter-employee" [clearable]="true"
                    [virtualScroll]="true" bindLabel="fullName" bindValue="id" placeholder="Trabajador"
                    formControlName="fEmployeeName" (change)="selectEmployee($event)">
                </ng-select>
            </div>
        </div>

        <div *ngIf="this.selectedTab == 2" class="form-group row">
            <label class="col-md-2 col-lg-2 col-xl-1 col-form-label">Fecha Inicio:</label>
            <div class="col-md-3 col-lg-3 col-xl-2 s-m-t-10">
                <input [owlDateTime]="filterFinDesdeDatePicker" [owlDateTimeTrigger]="filterFinDesdeDatePicker"
                    type="text" class="form-control form-control-sm date-field" placeholder="Inicio"
                    formControlName="filterFinDesdeDate"
                    [ngClass]="{'border-danger': !filterForm.get('filterFinDesdeDate').valid && filterForm.get('filterFinDesdeDate').touched}">
                <span class="text-danger"
                    *ngIf="!filterForm.get('filterFinDesdeDate').valid && filterForm.get('filterFinDesdeDate').touched">
                    Formato de fecha incorrecto (dd/mm/yyyy)
                </span>
                <owl-date-time #filterFinDesdeDatePicker [pickerType]="'calendar'"></owl-date-time>
            </div>
            <label class="col-md-2 col-lg-2 col-xl-1 col-form-label">Fecha Fin:</label>
            <div class="col-md-3 col-lg-3 col-xl-2 s-m-t-10">
                <input [owlDateTime]="filterFinHastaDatePicker" [owlDateTimeTrigger]="filterFinHastaDatePicker"
                    type="text" class="form-control form-control-sm date-field" placeholder="Fin"
                    formControlName="filterFinHastaDate"
                    [ngClass]="{'border-danger': !filterForm.get('filterFinHastaDate').valid && filterForm.get('filterFinHastaDate').touched}">
                <span class="text-danger"
                    *ngIf="!filterForm.get('filterFinHastaDate').valid && filterForm.get('filterFinHastaDate').touched">
                    Formato de fecha incorrecto (dd/mm/yyyy)
                </span>
                <owl-date-time #filterFinHastaDatePicker [pickerType]="'calendar'"></owl-date-time>
            </div>
            <div class="col-md-2 col-lg-2 col-xl-4 s-m-t-10">
                <button type="submit" class="btn-primary">Filtrar</button>
            </div>
        </div>
    </form>

    <nav class="add-exception-tabs">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="nav-matriculados-tab" data-toggle="tab" href="#nav-matriculados"
                    role="tab" aria-controls="nav-agregar" aria-selected="true" (click)="tabClick(1)"> TRABAJADORES
                    MATRICULADOS
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link ml-2" id="nav-realizados-tab" data-toggle="tab" href="#nav-realizados" role="tab"
                    aria-controls="nav-copiar" aria-selected="false" (click)="tabClick(2)"> CURSOS REALIZADOS
                </a>
            </li>
        </ul>
    </nav>
    <div class="card card-tabs">
        <div id="nav-tabContent" class="tab-content">
            <div id="nav-matriculados" class="tab-pane p-3 fade show active" role="tabpanel"
                aria-labelledby="nav-matriculados-tab">
                <div class="row mt-2">
                    <div class="col-md-12 d-flex s-m-t-10">
                        <table class="table">
                            <caption></caption>
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col" (click)="onChangeOrderDiplom(queryTypesDiplom[0])">
                                        NIF <span class="fa sort-arrow" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypesDiplom[0].direction === 'DESC', 
                                        'fa-sort-asc': queryTypesDiplom[0].direction === 'ASC' }"></span>
                                    </th>
                                    <th scope="col" (click)="onChangeOrderDiplom(queryTypesDiplom[1])">
                                        Nombre trabajadores <span class="fa" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypesDiplom[1].direction === 'DESC', 
                                        'fa-sort-asc': queryTypesDiplom[1].direction === 'ASC' }"></span>
                                    </th>
                                    <th scope="col">
                                        Telefono <span class="fa" aria-hidden="true"></span>
                                    </th>
                                    <th scope="col">
                                        E-Mail <span class="fa" aria-hidden="true"></span>
                                    </th>
                                    <th scope="col">
                                        Certificados <span class="fa" aria-hidden="true"></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let diplom of diplomados | paginate : paginationConfigDiplom">
                                    <td>{{diplom.trabajador_nif}}</td>
                                    <td>{{diplom.trabajador_txt}}</td>
                                    <td>{{diplom.trabajador_tel2}}</td>
                                    <td>{{diplom.trabajador_mail2}}</td>
                                    <td>
                                        <a class="btn btn-primary btn-icon" (click)="getCertificates(diplom)">
                                            VER
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pagination-div">
                        <div class="d-flex pl-0">
                            <pagination-controls id="pagination1" previousLabel="Anterior" nextLabel="Siguiente"
                                (pageChange)="pageChangedDiplomas($event)"
                                (pageBoundsCorrection)="pageChangedDiplomas($event)">
                            </pagination-controls>
                            <span class="float-left doc-find">Total de {{recCountDiplom}} trabajadores con diplomaturas encontrados</span>
                        </div>
                    </div>
                </div>
            </div>

            <div id="nav-realizados" class="tab-pane p-3 fade show" role="tabpanel"
                aria-labelledby="nav-realizados-tab">
                <div class="row mt-2">
                    <div class="col-md-12 d-flex s-m-t-10">
                        <table class="table">
                            <caption></caption>
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col" (click)="onChangeOrderCertif(queryTypesCertif[0])">
                                        Título de la formación <span class="fa sort-arrow" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypesCertif[0].direction === 'DESC', 
                                        'fa-sort-asc': queryTypesCertif[0].direction === 'ASC' }"></span>
                                    </th>
                                    <th scope="col" (click)="onChangeOrderCertif(queryTypesCertif[1])">
                                        Fecha Incio <span class="fa" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypesCertif[1].direction === 'DESC', 
                                        'fa-sort-asc': queryTypesCertif[1].direction === 'ASC' }"></span>
                                    </th>
                                    <th scope="col" (click)="onChangeOrderCertif(queryTypesCertif[2])">
                                        Fecha Fin <span class="fa" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypesCertif[2].direction === 'DESC', 
                                        'fa-sort-asc': queryTypesCertif[2].direction === 'ASC' }"></span>
                                    </th>
                                    <th scope="col" (click)="onChangeOrderCertif(queryTypesCertif[3])">
                                        Duración <span class="fa" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypesCertif[3].direction === 'DESC', 
                                        'fa-sort-asc': queryTypesCertif[3].direction === 'ASC' }"></span>
                                    </th>
                                    <th scope="col" (click)="onChangeOrderCertif(queryTypesCertif[4])">
                                        Núm. Trabajadores <span class="fa" aria-hidden="true" [ngClass]="{'fa-sort-desc': queryTypesCertif[4].direction === 'DESC', 
                                        'fa-sort-asc': queryTypesCertif[4].direction === 'ASC' }"></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let certificate of certificates | paginate : paginationConfigCertif"
                                    (click)="gotoTrainingDetails(certificate)">
                                    <td>{{certificate.formacion_nombre}}</td>
                                    <td>{{_commonUiService.dateStringFormat(certificate.fecha_ini)}}</td>
                                    <td>{{_commonUiService.dateStringFormat(certificate.fecha_fin)}}</td>
                                    <td>{{certificate.duracion}}</td>
                                    <td>{{certificate.aprobados}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pagination-div">
                        <div class="d-flex pl-0">
                            <pagination-controls id="pagination2" previousLabel="Anterior" nextLabel="Siguiente"
                                (pageChange)="pageChangedCertificate($event)"
                                (pageBoundsCorrection)="pageChangedCertificate($event)">
                            </pagination-controls>
                            <span class="float-left doc-find">Total de {{recCountCertif}} cursos de formación encontrados</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>